*,
*:before,
*:after {
	font-kerning: normal;
}

body {
	&.has-banner {
		@include xy-grid-container(100%, 0);
	}
}

$hr-width-xsmall: 36px !default;
$hr-width-small: 60px !default;

///
//// Custom Components

blockquote {
	margin-bottom: 15px;

	cite {
		padding-top: 3px;
	}
}

$list-item__margin: 3px;

ul > li,
ol > li {
	margin-bottom: $list-item__margin * 2;

	ul li,
	ol li {
		margin-top: $list-item__margin;
	}
}

dt:not(:first-of-type) {
	margin-top: $list-item__margin * 4;
}

code {
	font-size: rem-calc(13);
}

// Sorry if a real image needs this, Tracking pixels should only be 1px x 1px
body > img {
	position: absolute;
}

// IE10+
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	img {
		width: 100%;
	}
}

///
// Custom Classes

.grid-container {
	width: 100%;
}

.row {
	@include xy-grid-container($padding: 0);

	&.full-width {
		max-width: none;
	}

	.columns {
		@include xy-grid();
		align-content: flex-start;

		.columns {
			@include xy-grid(vertical);
		}

		&.text-center {
			justify-content: center;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

// Custom HR Sizes
hr {
	border-width: 2px;

	&.x-small {
		width: $hr-width-xsmall;
	}

	&.small {
		width: $hr-width-small;
	}
}

.light-text {
	color: $white;

	* {
		color: inherit;
	}
}

// https://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/
.page-wrapper {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	position: relative;

	> * {
		width: 100%;
	}

	.page-main {
		flex: 1 0 auto;
	}

	.page-header,
	.page-footer {
		flex-shrink: 0;
	}
}

.page-main {
	margin: {
		top: $grid-container-padding * 2;
		bottom: $grid-container-padding * 3;
	}
	// No margins on homepage
	body.cms-index-index &,
	body.page-layout-1column-no-breadcrumbs & {
		margin-bottom: 0;
		margin-top: 0;
	}
}

// Titles
.page-title-wrapper {
	@include xy-grid-container;
	margin-bottom: rem-calc(40);
	margin-top: divide($grid-container-padding, 3);
	text-align: center;
}

// Banners
.banner-title {
	@include xy-grid-container(100%);
	background: $primary-color;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: rem-calc(54);
	position: relative;
	top: rem-calc(-54);
	left: 0;
	right: 0;

	h1 {
		color: $white;
		letter-spacing: $header-letter-spacing * 2;
		text-align: center;
		max-width: rem-calc(300);

		@include breakpoint($screen__xs) {
			max-width: rem-calc(600);
			padding: rem-calc(0 30);
		}

		@include breakpoint(medium) {
			max-width: rem-calc(900);
		}
	}
}

ul.stacking-images {
	@include list-reset();
	width: 100%;

	li {
		@include xy-cell($gutters: 3px);
		margin-bottom: rem-calc(9);
		margin-top: 0;
	}

	@include breakpoint(medium) {
		@include xy-grid($wrap: false);
		margin-bottom: 0;

		&.stack-2 {
			li {
				@include xy-cell(1 of 2, $gutters: 9px);
			}
		}

		&.stack-3 {
			li {
				@include xy-cell(1 of 3, $gutters: 12px);
			}
		}
	}

	~ .vertical-spacer {
		margin-bottom: 0;
	}
}

.vertical-spacer {
	background-color: map-get($lfi-palette, latte);
	border-color: map-get($lfi-palette, latte);
	color: map-get($lfi-palette, latte);
	height: rem-calc(60);
	margin: rem-calc(21) auto;
	text-align: center;
	width: rem-calc(2);
}

.no-border {
	border: none !important;
}
